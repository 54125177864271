.scroll-container {
  max-height: 20vh;
  max-width: 70vw;
  overflow: scroll;
  border: 2px solid #72757a;
  padding: 10px;
}

.loading-indecator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.surroundMargin {
  margin: 10px;
}
